export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/agreement": [3],
		"/dashboard/check": [4],
		"/dashboard/feedback": [5],
		"/dashboard/in-progress": [6],
		"/dashboard/not-registered": [7],
		"/dashboard/order-confirmation": [9],
		"/dashboard/order": [8],
		"/dashboard/register": [10],
		"/dashboard/register/order-confirmation": [11],
		"/dashboard/subscription-confirmation": [12],
		"/kartlegging/[organization_name]": [13],
		"/my-page": [14],
		"/prescription_warning": [15],
		"/user/profile": [16],
		"/vaksinasjonsplan/[organization_name]": [17],
		"/vaksinasjonsplan/[organization_name]/not-registered": [18]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';